<script lang="ts" setup>
const { url } = useImageStorage()

withDefaults(
  defineProps<{
    display?: boolean
    emptyWrapperClass?: string
    emptyClass?: string
    message?: string
    emptyImageUrl?: string
    emptyImageSize?: number
    useButton?: boolean
    buttonUseLeading?: boolean
    buttonLeadingImageUrl?: string
    buttonLeadingImageClass?: string
    buttonText?: string
  }>(),
  {
    display: false,
    emptyWrapperClass: 'my-14',
    emptyClass: 'gap-y-2',
    message: '',
    emptyImageUrl: '/assets/icon/message_send.svg',
    emptyImageSize: 40,
    useButton: false,
    buttonUseLeading: false,
    buttonLeadingImageUrl: '',
    buttonLeadingImageClass: '',
    buttonText: '',
  },
)

defineEmits([
  'click:no-content-button',
])
</script>

<template>
  <div
    v-show="display"
    class="w-full text-center text-lg font-semibold"
    :class="emptyWrapperClass"
  >
    <div
      class="h-96 flex flex-col items-center justify-center"
      :class="emptyClass"
    >
      <NuxtImg
        :src="url(true, emptyImageUrl)"
        :width="emptyImageSize"
        :height="emptyImageSize"
        loading="eager"
      />
      <span class="text-sm text-gray04Light dark:text-gray04Dark">
        {{ message }}
      </span>
      <slot />
      <AButton
        v-if="useButton"
        custom-class="mt-1"
        button-variant="soft"
        button-size="lg"
        button-color="black"
        button-confirm-color="green"
        :use-leading="buttonUseLeading"
        :image-url="url(true, buttonLeadingImageUrl)"
        :image-class="buttonLeadingImageClass"
        :button-text="buttonText"
        @click="() => $emit('click:no-content-button')"
      />
    </div>
  </div>
</template>
